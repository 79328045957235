import React, { useState, useEffect } from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import Image from "../../assets/images/create-new-password.svg"
import { navigate } from "gatsby"
import { Container } from "../../components/compartilhados/Container"
import { Main, InfoWrapper, InputsWrapper, PasswordWrapper, ImageWrapper } from "../../styles/saude/cadastro-nova-senha"
import CheckPasswords from "../../components/profissionaisSaude/Inputs/CheckPasswords"

export default function CadastroNovaSenha() {
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [isPasswordInputActive, setIsPasswordInputActive] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [errorPassword1, setErrorPassword1] = useState("")
    const [errorPassword2, setErrorPassword2] = useState("")

    const redefinePassword = () => {
        if(isValid){
            //Lógica para redefinir senha
            navigate("/")
        }else{
            //Lógica caso as senhas não possuam todos os requisitos
        }

        if(password1.length === 0)
            setErrorPassword1("Este campo é obrigatório.")

        if(password2.length === 0)
            setErrorPassword2("Este campo é obrigatório.")
    }

    useEffect(() => {
        setErrorPassword1("")
        setErrorPassword2("")
    }, [password1, password2])

    return (
        <Container>
            <Header />
            <Main>
                <InfoWrapper>
                    <h1>Cadastre nova senha</h1>
                    <InputsWrapper>
                        <PasswordWrapper>
                            <TextInput
                                label="Nova senha"
                                placeholder="Digite a nova senha"
                                isPassword
                                large={true}
                                onFocus={() => setIsPasswordInputActive(true)}
                                onBlur={() => setIsPasswordInputActive(false)}
                                onChange={(e) => {setPassword1(e.target.value)}}
                                error={errorPassword1}
                            />
                            <TextInput
                                label="Confirmar nova senha"
                                placeholder="Repita a nova senha"
                                isPassword
                                large={true}
                                onFocus={() => setIsPasswordInputActive(true)}
                                onBlur={() => setIsPasswordInputActive(false)}
                                onChange={(e) => {setPassword2(e.target.value)}}
                                error={errorPassword2}
                            />
                        </PasswordWrapper>
                        <CheckPasswords
                            passwords={{
                                password: password1,
                                passwordConfirmation: password2,
                                isActive: isPasswordInputActive,
                                isPasswordsMatchs: password1 === password2
                            }}
                            onValid={setIsValid}
                        />
                        <Button
                            title="Redefinir senha"
                            noLink
                            onClick={redefinePassword}
                        />
                    </InputsWrapper>
                </InfoWrapper>
                <ImageWrapper>
                    <Image className="main-image" />
                </ImageWrapper>
            </Main>
            <Footer hasButtonUp />
        </Container>
    )
}
