import styled from "styled-components"

export const Main = styled.div`
    margin: ${({ theme }) => theme.spacing['2xl']} 0 ${({ theme }) => theme.spacing.layoutl} 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 13.375rem;
    min-height: calc(100vh - 20.25rem);

    @media screen and (max-width: 1020px) {
        padding: 1rem 2.5rem;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: ${({ theme }) => theme.spacing.m};
    }
`
export const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
  max-width: 28.1875rem;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.colors.gray.text_base};
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXL};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
  }
`

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};
  color: ${({ theme }) => theme.colors.gray.text_base};

  span {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
`

export const PasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.s};
`

export const ImageWrapper = styled.section`
  max-width: 28.1831rem;
  max-height: 22.8856rem;

  .main-image {
    width: 100%;
    height: 100%;
  }
`